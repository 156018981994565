@import "node_modules/nord/src/sass/nord.scss";

.site .stories {
  min-height: 22rem;
  display: grid;
  grid-gap: 0.1rem;
}

.site {
  max-width: 40rem;
  border: $nord1 solid;
  padding: 0.2rem;
  margin: 0 0.1rem 0 0;
}

.site .next {
  float: right;
}

.site {
  color: $nord7;
  overflow: hidden;
}

.site a {
  text-decoration: none;
  color: $nord6;
}

.site a:visited {
  color: $nord13;
}

.about a {
  color: $nord7;
}

.about a:visited {
  color: $nord15;
}

.about {
  color: $nord4;
}

.configuration {
  color: $nord4;
}

select {
  background-color: #090a1b;
  border: $nord3 solid 2px;
  color: $nord4;
  margin-left: 10px;
}
input {
  background-color: #090a1b;
  border: $nord3 solid 2px;
  color: $nord4;
  margin-left: 10px;
}

.site .details {
  font-size: 0.75rem;
}

.story .title .domain {
  font-size: 0.5rem;
  color: $nord9;
}

.mute {
  color: $nord12;
  cursor: pointer;
}

.site .index {
  font-size: 0.7rem;
}

.app .container {
  margin: 0.5rem;
  display: grid;
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  justify-items: center;
}

@media only screen and (max-width: 599px) {
  .app .container {
    grid-template-columns: repeat(1, 1fr);
  }
  .tr {
    grid-column: 1;
    grid-row: 2;
  }
  .bl {
    grid-column: 1;
    grid-row: 3;
  }
  .br {
    grid-column: 1;
    grid-row: 4;
  }
  .configuration {
    grid-column: 1;
    grid-row: 5;
  }
  .about {
    grid-column: 1;
    grid-row: 6;
  }
  .site {
    min-width: 18rem;
  }
}

@media only screen and (min-width: 900px) {
  .app .container {
    grid-template-columns: repeat(2, 1fr);
  }
  .tr {
    grid-column: 2;
    grid-row: 1;
  }
  .bl {
    grid-column: 1;
    grid-row: 2;
  }
  .br {
    grid-column: 2;
    grid-row: 2;
  }
  .configuration {
    grid-column: 1;
    grid-row: 3;
  }
  .about {
    grid-column: 2;
    grid-row: 3;
  }
  .site {
    min-width: 30rem;
  }
}

.tl {
  grid-column: 1;
  grid-row: 1;
}

.clear {
  float: clear;
}

.site .refresh {
  float: right;
  margin: 0;
  padding: 0 0.2rem;
  cursor: pointer;
}

.container .configuration {
  justify-self: left;
}

.timerCollection {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.timer {
  display: grid;
  grid-template-columns: 1fr;
  color: $nord5;
  font-size: 1em;
  align-self: center;
  align-content: center;

  .type {
    font-size: 0.75em;
  }
}

button {
  background-color: #0e1d2a;
  color: $nord4;
  border: $nord3 solid 2px;
  border-radius: 2px;
  margin: 5px;
}

button:hover {
  background-color: #1b2950;
}

body {
  background-color: $nord0;
}
